<template>
    <div>
        <table-item-claims
        :tabSelected="'solution'"
        ></table-item-claims>
    </div>
</template>
<script>
import tableItemClaims from "@/views/quality/views/settings/views/tableItemClaims.vue";
export default {
    components: {
        "table-item-claims": tableItemClaims,
    }
}
</script>